<template>
  <div>
    <!-- Summry Card -->
    <b-row>
      <b-col
        md="12"
        lg="12"
      >
        <b-card
          text-variant="white"
          bg-variant="primary"
          align="center"
          class="position-static"
        >
          <blockquote class="blockquote  mb-0">
            <p>포인트 전환 방법</p>
            <footer class="blockquote-footer text-white opacity-75">
              <small>
                <cite title="Source Title">보유 포인트 캐시 전환시 즉시 전환됩니다</cite>
              </small>
            </footer>
          </blockquote>
        </b-card>
      </b-col>
    </b-row>
    <div class="row">
      <div class="col-md-8 d-flex flex-column">
        <b-card class="h-100">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="현재 Point"
                label-for="hi-first-name"
                label-cols-md="4"
                class="d-flex align-items-center"
              >
                <b-input-group class="input-group-merge">
                  <div
                    class="text-success"
                  >
                    {{ Number(userData.point).toLocaleString() }}
                  </div>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="전환 요청 Piont"
                label-for="hi-email"
                label-cols-md="4"
                class="d-flex align-items-center"
              >
                <b-input-group>
                  <div
                    class="text-warning"
                  >
                    {{ Number(exchangeAmt).toLocaleString() }}
                  </div>
                </b-input-group>
              </b-form-group>
            </b-col>
            <hr>
            <b-col cols="12">
              <b-form-group
                label="전환 후 Piont"
                label-for="hi-password"
                label-cols-md="4"
              >
                <b-input-group class="input-group-merge">
                  <div class="text-success">
                    {{ Number(finalPoints).toLocaleString() }}
                  </div>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </div>
      <div class="col-md-4 d-flex flex-column">
        <b-card class="h-100">
          <b-col class="">
            <div class="justify-content-center">
              <div class="row">
                <div
                  class="col-6"
                  style="padding-right: 2px"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-secondary"
                    class="btn-num btn-block"
                    @click="addExchangeAmtByButton(1000)"
                  >1천</b-button>
                </div>
                <div
                  class="col-6"
                  style="padding-left: 2px"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-secondary"
                    class="btn-num btn-block"
                    @click="addExchangeAmtByButton(5000)"
                  >5천</b-button>
                </div>
                <div
                  class="col-6"
                  style="padding-right: 2px"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-secondary"
                    class="btn-num btn-block"
                    @click="addExchangeAmtByButton(10000)"
                  >1만</b-button>
                </div>
                <div
                  class="col-6"
                  style="padding-left: 2px"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-secondary"
                    class="btn-num btn-block"
                    @click="addExchangeAmtByButton(50000)"
                  >5만</b-button>
                </div>
                <div
                  class="col-6"
                  style="padding-right: 2px"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-secondary"
                    class="btn-num btn-block"
                    @click="addExchangeAmtByButton(100000)"
                  >10만</b-button>
                </div>
                <div
                  class="col-6"
                  style="padding-left: 2px"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-secondary"
                    class="btn-num btn-block"
                    @click="addExchangeAmtByButton(200000)"
                  >20만</b-button>
                </div>
                <div class="col-12">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-secondary"
                    class="btn-num btn-block font-small-3"
                    @click="addExchangeAmtByButton(userData.point)"
                  >전액</b-button>
                </div>
                <div class="col-12">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-secondary"
                    class="btn-num btn-block font-small-3"
                    @click="addExchangeAmtByButton(0)"
                  >Reset</b-button>
                </div>
              </div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-warning"
                class="btn-block mt-1"
                @click="confirmExchange()"
              >전환신청</b-button>
            </div>
          </b-col>
        </b-card>
      </div>
    </div>

    <!-- Table -->
    <b-card>
      <b-row class="justify-content-between">
        <!-- Table Fitler -->
        <b-col
          lg="3"
          md="4"
        >
          <b-form-group
            class="font-small-3"
            label="Show"
            label-for="table-perPage"
            label-cols-lg="3"
            label-cols-md="4"
            label-align-sm="left"
          >
            <b-form-select
              id="table-perPage"
              v-model="perPage"
              :options="perPageOptions"
              size="sm"
              :clearable="false"
              class="half-width"
            />
          </b-form-group>
        </b-col>

        <!-- Table Content -->
        <b-col sm="12">
          <b-table
            responsive
            show-empty
            striped
            hover
            small
            empty-text="No matching records found"
            class="no-select"
            style="text-align: center; font-size: small"
            :items="paginatedItems"
            :fields="fetchPointExchangeFields"
          >
            <template #cell(index)="data">
              {{ totalRows - (perPage * (currentPage - 1) + data.index) }}
            </template>
            <template #cell(createdAt)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 13rem"
              >
                {{ data.item.createdAt | formatDate('YYYY-MM-DD HH:mm') }}
              </div>
            </template>
            <template #cell(categoryDetail)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 5rem"
              >
                {{ data.item.categoryDetail }}
              </div>
            </template>
          </b-table>
        </b-col>

        <!-- Table Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-left justify-content-left justify-content-sm-start pl-4"
          style="margin-bottom: 0.5rem;"
        >
          <span class="font-small-2">{{ pageFrom }}~{{ pageTo }} / 총 {{ pageOf }} 건 </span>
        </b-col>
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>

    <div>
      <!-- <p>fetchRequestAlarmsBranchCashoutFinish: {{ fetchRequestAlarmsBranchCashoutFinish.length }} </p> -->
      <!--      <p>Cash: {{ Number(userData.cash).toLocaleString() }} </p>-->
      <!--      <p>Point: {{ Number(userData.point).toLocaleString() }} </p>-->
      <!--      <p>ExchangeAmt: {{ Number(exchangeAmt).toLocaleString() }} </p>-->
      <!-- <p>paginatedItems: {{ paginatedItems }} </p> -->
      <!--      <p>userAddExchangeAmt: {{ userAddExchangeAmt }} </p>-->
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BInputGroup,
  BFormSelect,
  BTable,
  BPagination,
  BButton,
  BBadge,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { createNamespacedHelpers } from 'vuex'
import { REQUEST_POINT_EXCHANGE } from '@/store/exchange/mutation'
import { FETCH_LOGS_USERS_DETAIL_POINT } from '@/store/logs/action'

import * as moment from 'moment-timezone'

const exchangeStore = createNamespacedHelpers('exchangeStore')
const logStore = createNamespacedHelpers('logStore')

moment().tz('Asia/Seoul')

export default {
  name: 'ExchangePointToCash',
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
    BFormGroup,
    BInputGroup,
    BFormSelect,
    BTable,
    BPagination,
    BButton,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      currentURL: window.location.href,
      componentName: this.$options.name,
      userData: JSON.parse(localStorage.getItem('userData')),
      userIp: localStorage.getItem('ip'),
      periodFrom: moment(new Date()).startOf('month').format('YYYY-MM-DD HH:mm'), // 당월 첫째날
      periodTo: moment(new Date()).endOf('month').format('YYYY-MM-DD HH:mm'), // 당월 마지막날
      searchFilter: '',
      perPageOptions: [10, 25, 50, 100],
      currentPage: 1, // 현재 페이지
      perPage: 10, // 페이지 당 표시할 항목 수
      pageFrom: 0,
      pageTo: 0,
      pageOf: 0,
      totalRows: 0, // 총 메시지 수 (서버에서 얻을 수도 있습니다)
      isExpandChecked: false,
      fetchPointExchangeFields: [
        { key: 'index', label: 'No.' },
        // { key: 'baseDate', label: '변경일자' },
        { key: 'createdAt', label: '변경일시' },
        { key: 'title', label: '타이틀' },
        { key: 'titleSub', label: '서브타이틀' },
        { key: 'category', label: '구분' },
        { key: 'categorySub', label: '유형' },
        { key: 'userid', label: '변경유저명' },
        { key: 'asisContent', label: '변경전' },
        { key: 'tobeContent', label: '변경후' },
        { key: 'changeContent', label: '변경내용' },
        { key: 'memo', label: '메모' },
        // { key: 'systemType', label: '타입' },
        // { key: 'systemTypeDetail', label: '타입상세' },
        // { key: 'performer', label: '작업주체' },
        // { key: 'adminId', label: '작업자ID' },
        // { key: 'adminIp', label: '작업자IP' },
      ],
      userAddExchangeAmt: 0,
      exchangeAmt: 0,
    }
  },
  computed: {
    ...exchangeStore.mapGetters([
      'fetchPointExchange',
    ]),
    ...logStore.mapGetters({
      fetchLogsUsersDetailPoint: 'fetchLogsUsersDetailPoint',
    }),
    // 검색필터된 Items
    filteredItems() {
      let filteredItems = this.fetchLogsUsersDetailPoint
      if (this.searchFilter) {
        filteredItems = filteredItems.filter(item => Object.values(item).some(val => val && val.toString().toLowerCase().includes(this.searchFilter.toLowerCase())))
      }
      return filteredItems
    },
    // Table Filtered Items => Pagination Items
    paginatedItems() {
      const totalFilteredItems = this.filteredItems.length
      const start = totalFilteredItems - (this.currentPage * this.perPage)
      const end = start + this.perPage

      const paginatedItems = this.filteredItems.slice(Math.max(0, start), end).reverse()

      this.totalRows = totalFilteredItems
      this.pageFrom = (this.currentPage - 1) * this.perPage + 1 // 현재 페이지의 첫 번째 항목
      this.pageTo = Math.min(this.totalRows, this.currentPage * this.perPage) // 현재 페이지의 마지막 항목 또는 전체 항목 수 중 작은 값
      this.pageOf = this.totalRows

      return paginatedItems
    },
    // "환전 후 Point" 계산
    finalPoints() {
      if (this.exchangeAmt > 0) {
        if (!this.userData || !this.userData.point) {
          return 0 // userData가 없거나 userData.point가 없을 경우 0을 반환
        }
        const currentPoints = Number(this.userData.point)
        const exchangeAmount = Number(this.exchangeAmt)
        const result = currentPoints - exchangeAmount
        return result >= 0 ? result : 0 // 결과가 음수가 되지 않도록 처리
      }
      return 0
    },
  },
  created() {
    console.log(this.componentName, 'created()')
  },
  mounted() {
    this.$socket.emit('handleMenuEntry', {
      site: this.$site,
      userid: JSON.parse(localStorage.getItem('userData')).userid,
      menu: 'point',
      menuCategory: '',
    })
    // console.log(this.componentName, 'mounted()')
    this.fetchData()
  },
  beforeDestroy() {
    this.$socket.emit('handleMenuEntry', {
      site: this.$site,
      userid: JSON.parse(localStorage.getItem('userData')).userid,
      menu: '',
      menuCategory: '',
    })
  },
  methods: {
    ...exchangeStore.mapActions({
      $requestPointExchange: REQUEST_POINT_EXCHANGE,
    }),
    ...logStore.mapActions({
      $fetchLogsUsersDetailPoint: FETCH_LOGS_USERS_DETAIL_POINT,
    }),
    async fetchData() {
      // await this.$fetchPointExchange({
      //   site: this.userData.site,
      //   userid: this.userData.userid,
      //   periodFrom: this.periodFrom,
      //   periodTo: this.periodTo,
      // })
      await this.$fetchLogsUsersDetailPoint({
        site: this.userData.site,
        periodFrom: this.periodFrom,
        periodTo: this.periodTo,
        userid: this.userData.userid,
        titleSub: '포인트전환',
        categorySub: '포인트',
      })
    },

    // 버튼을 눌렀을 때의 함수
    addExchangeAmtByButton(amount) {
      if (amount === 0) {
        this.exchangeAmt = 0
        this.userAddExchangeAmt = 0
        return
      }
      // 버튼을 눌렀을 때, 직접 입력 값에 해당 금액을 더합니다.
      this.userAddExchangeAmt += amount
      // 현재 값에 버튼을 눌렀을 때의 값을 더합니다.
      const newExchangeAmt = this.exchangeAmt + amount

      if (newExchangeAmt > this.userData.point) {
        this.showToast('보유 포인트를 초과하는 금액은 전환할 수 없습니다.')
        this.exchangeAmt = this.userData.point
        this.userAddExchangeAmt = this.userData.point
      } else {
        this.exchangeAmt = newExchangeAmt
      }
    },
    async confirmExchange() {
      await Swal.fire({
        title: '변경내용을 저장하시겠습니까?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.requestPointExchange()
        }
      })
    },
    async requestPointExchange() {
      try {
        await this.$requestPointExchange({
          site: this.userData.site,
          userid: this.userData.userid,
          point: Number(this.exchangeAmt),
          userIp: this.userIp,
        })
      } catch {
        console.error()
      } finally {
        Swal.fire({
          title: '전환 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(result => {
          console.log(`result: ${result}`)
          this.exchangeAmt = 0
          this.userAddExchangeAmt = 0
          this.fetchData()
        })
      }
    },
    showToast(title) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'InfoIcon',
          variant: 'info',
        },
      })
    },
  },
}
</script>

<style lang="scss">
  .btn-num {
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    margin-bottom: 5px;
    //box-shadow: 0 0 3px #eee;
    border-radius: 5px;
    //background-image: linear-gradient(to right, #fbc2eb 0%, #a6c1ee 51%, #fbc2eb 100%);
    background-image: linear-gradient(to right, #2b353f 0%, #353f68 51%, #273e5f 100%);

    &:hover {
      background-position: right center; /* change the direction of the change here */
      text-align: center;
    }
  }
</style>
